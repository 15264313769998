<template>
    <div>
        <ElementsInfo v-model="showError">Bitte geben Sie uns die Erlaubnis, auf die Zwischenablage zuzugreifen.</ElementsInfo>
        <label class="block mb-2 text-base transition-all duration-300" v-bind:class="{ 'opacity-0 -translate-x-2': !active }">
            Code aus Authenticator
            <span class="inline-block text-gray-400">*</span>
        </label>
        <div class="flex justify-between">
            <input v-for="i in 6" :key="i" v-model="internalModel[i-1]"
                @textInput="handleTextInput" @paste="pasteCode" @keyup="handleKeyUp"  @input="handleInputDone"
                type="number" maxlength="1" min="0" max="9" required :tabindex="i" autocomplete="off" :placeholder="i"
                class="transition-all ease-over-out duration-200 text-[3rem] border-b-2 w-[3.2rem] text-center outline-none focus:border-b-secondary dark:focus:border-b-primary
                        placeholder:text-gray-200
                        dark:bg-transparent dark:placeholder:text-gray-600"
                v-bind:class="{
                    'border-b-primary dark:border-b-secondary focus:border-b-primary dark:focus:border-b-secondary' : modelValue?.[i-1]?.length,
                    'border-b-red-600' : error,
                    'scale-75 opacity-0': !active,
                }"
                v-bind:style="{'transition-delay': !activeDone ? (i*80) + 'ms' : '0ms' }"
            />
        </div>
        <div class="flex justify-center my-8 transition-all ease-over-out duration-200" v-bind:class="{ 'translate-y-4 opacity-0' : !active }">
            <ElementsButton v-if="navigatorPermissions" @click="pasteCode" icon="duplicate">Einfügen</ElementsButton>
        </div>
    </div>
</template>
<script setup>
const props = defineProps({
    modelValue: { default: null },
    validation: { type: Object, default: { autovalidate: () => {}, add: () => {}, remove: () => {} } },
    error: { type: Boolean, default: false },
})
const emit = defineEmits(['update:modelValue', 'submit'])

const internalModel = ref([])

const showError = ref(false)
const navigatorPermissions = typeof navigator?.clipboard?.readText == 'function'

watch(internalModel.value, () => {
    let fullCode = internalModel.value?.join('')
    emit('update:modelValue', fullCode )
    if(fullCode?.length == 6) emit('submit')
})
const pasteCode = async () => {
    try {
        let clipboard = await navigator.clipboard.readText()
        clipboard = clipboard?.replaceAll(' ', '')
    
        if(clipboard?.length == 6){
            internalModel.value = clipboard.split('')
            emit('update:modelValue', clipboard )
            emit('submit')
        }
    } catch(e) {
        showError.value = true
    }
}

const handleInputDone = (event) => {
    let val = String(event?.target?.value)?.replaceAll(' ', '')
    if( val?.length == 6 ){
        for(let i = 0; i < val?.length; i++){
            internalModel.value[i] = val?.[i]
        }
        emit('submit')
    }
    else {
        let target = event.target
        for(let i = 0; i < val?.length; i++){
            target.value = val?.[i]
            target = target?.nextElementSibling
        }
        target?.focus()
    }
}
const handleTextInput = (event) => {
    if( isNaN(event.data) ) return event.preventDefault()

}
const handleKeydown = (event) => {
    if( event.key == 'v' && event.ctrlKey){
        pasteCode()
    }
    else if( event.key.length == 1 && isNaN(event.key) ) return event.preventDefault()

}
const handleKeyUp = (event) => {
    if( event.code == 'Backspace' || event.key == 'Backspace' || event.code =='ArrowLeft'){
        console.log('goback')
        event.target?.previousElementSibling?.focus()
    }
    else if(event.target?.value)
        event.target?.nextElementSibling?.focus()
}

const active = ref(false)
const activeDone = ref(false)
onMounted(function(){
    setTimeout(function(){ 
        active.value = true
        setTimeout(() => activeDone.value = true, 800)
    }, 150)
})
onBeforeUnmount(() => active.value = false )
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>